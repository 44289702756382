define("HealthServiceUtils", [], function () {
    "use strict";

    var ServiceTypes = {
        REFERRAL: "REFERRAL",
        ORDER: "ORDER",
        REPORT: "REPORT",
    };

    // Gets the service type of a single offering
    function getServiceType(offering) {
        // If the health service is not in the mapping, default it to the REFERRAL type
        return healthServiceTypeMap[offering.service] || ServiceTypes.REFERRAL;
    }

    // Checks if a list of offerings contains only a single service type, and returns it, or null otherwise
    function checkForSingleServiceType(offerings) {
        if (offerings == null || offerings.length === 0) {
            return null;
        }
        var areAllServiceTypesEqual = offerings.every(offering => offering.service === offerings[0].service);
        return areAllServiceTypesEqual ? offerings[0].service : null;
    }

    // Based on the referral target and/or offering, generate a button text for sending out the service
    // WIP - will potentially need to upgrade and reuse this method in other areas where the Report vs Order vs Referral text logic is relevant, specifically in the Referrals.getTerm method, which will have a trickle down effect to a bunch of text
    function getSendButtonText(referralTarget, offering) {
        var serviceType = null;
        if (offering != null) {
            serviceType = getServiceType(offering);
        }
        if (referralTarget != null && offering == null) {
            serviceType = checkForSingleServiceType(referralTarget.offerings);
        }
        return "Send Referral";
        // The wording is still undecided, so the button text will remain unchanged for now, until the details are decided for the type of service
    }

    // A mapping of health services to their respective service type
    var healthServiceTypeMap = {
        AEFI: ServiceTypes.REPORT,
        AMBULATORY_BP: ServiceTypes.ORDER,
        ARTERIAL_BLOOD_GASES: ServiceTypes.ORDER,
        BIOPHYSICAL_PROFILE: ServiceTypes.ORDER,
        BONE_MINERAL_DENSITY: ServiceTypes.ORDER,
        BONE_SCAN: ServiceTypes.ORDER,
        BREAST_ULTRASOUND: ServiceTypes.ORDER,
        BRONCHIAL_CHALLENGE: ServiceTypes.ORDER,
        CARDIAC_STRESS_TESTING: ServiceTypes.ORDER,
        CARDIOLOGY_SERVICES: ServiceTypes.ORDER,
        CENTRAL_VENOUS_CATHETER_INSERTION: ServiceTypes.ORDER,
        CT: ServiceTypes.ORDER,
        DIAGNOSTIC_IMAGING: ServiceTypes.ORDER,
        ECG: ServiceTypes.ORDER,
        ECHOCARDIOGRAM: ServiceTypes.ORDER,
        EEG: ServiceTypes.ORDER,
        EMG: ServiceTypes.ORDER,
        EXERCISE_OXIMETRY: ServiceTypes.ORDER,
        FINE_NEEDLE_ASPIRATION: ServiceTypes.ORDER,
        FLUOROSCOPY: ServiceTypes.ORDER,
        GASTRIC_EMPTYING_STUDY: ServiceTypes.ORDER,
        HOLTER_MONITORING: ServiceTypes.ORDER, // the description for this is Ambulatory ECG Monitoring
        HYPERBARIC_THERAPY: ServiceTypes.ORDER,
        IMAGING_GUIDED_NEEDLE_BIOPSY: ServiceTypes.ORDER,
        INTRAVENOUS_IRON_THERAPY: ServiceTypes.ORDER,
        LABORATORY: ServiceTypes.ORDER,
        LUMBAR_PUNCTURE: ServiceTypes.ORDER,
        MAMMOGRAPHY: ServiceTypes.ORDER,
        MRI: ServiceTypes.ORDER,
        MUGA: ServiceTypes.ORDER,
        MYOCARDIAL_PERFUSION_STUDY: ServiceTypes.ORDER,
        NEUROLOGY_SERVICES: ServiceTypes.ORDER,
        NURSING: ServiceTypes.ORDER,
        OBSP: ServiceTypes.ORDER,
        OBSTETRICAL_3D_ULTRASOUND: ServiceTypes.ORDER,
        OBSTETRICAL_ULTRASOUND: ServiceTypes.ORDER,
        PARACENTESIS: ServiceTypes.ORDER,
        PATHOLOGY: ServiceTypes.ORDER,
        PEDIATRIC_LABORATORY: ServiceTypes.ORDER,
        PET_SCAN: ServiceTypes.ORDER,
        ULTRASOUND: ServiceTypes.ORDER,
        UPPER_GI_SERIES: ServiceTypes.ORDER,
        VASCULAR_STUDIES: ServiceTypes.ORDER,
        X_RAY: ServiceTypes.ORDER,
    }

    return {
        getSendButtonText: getSendButtonText,
    };
});